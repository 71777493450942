<template>
    <div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6 w-full flex items-center">
                <vs-button v-if="userPermission.edit" class="float-left" color="success" style="width:100%;" @click="handleProcess()">
                    Process
                </vs-button>
            </div>
            <div class="vx-col sm:w-1/6 w-full flex items-center">
                <vs-button v-if="userPermission.cancel" class="float-left" color="danger" style="width:100%;" @click="handleCancel()">
                    Cancel
                </vs-button>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-0 w-full" v-model="search" placeholder="Search by : SO Code or Customer Name" />
                        </div>
                    </div>
                    <div class="vx-col sm:w-1/12 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-button v-if="userPermission.view" class="mb-4 w-full" color="primary" type="border" icon-pack="feather" icon="icon-search" v-on:click="handleFilters()"></vs-button>
                        </div>
                    </div>
                </div>

                <vs-table :data="dataSalesOrder" stripe border>
                    <template slot="thead">
                        <vs-th>
                            Action
                            <vs-checkbox v-on:click="checkAllProcess()" v-model="checkedAll"></vs-checkbox>
                        </vs-th>
                        <vs-th>Customer Data</vs-th>
                        <vs-th>SFA SO Number</vs-th>
                        <vs-th>SO Data</vs-th>
                        <vs-th>SO Type</vs-th>
                        <vs-th>Delivery Data</vs-th>
                        <vs-th>Segmentation Data</vs-th>
                        <vs-th>Order Summary</vs-th>
                        <vs-th>Status & Note</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
                            <vs-td>
                                <div class="vx-row mb-4 pl-3">
                                    <feather-icon v-if="userPermission.edit" title="Edit" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickEdit(dt)"/><br>
                                    <vs-checkbox v-if="userPermission.edit" :checked="checked_so.includes(dt.DefaultAttribute.ID)" v-on:click="checkProcess(dt.DefaultAttribute.ID)"></vs-checkbox><br>
                                </div>
                            </vs-td>
                            <vs-td>
                                Customer : <b>{{dt.Customer.Code}} - {{dt.IsOneTime ? dt.Customer.Name + ' | ' + dt.OcData.name : dt.Customer.Name}}</b> <br>
                                Ship to Code : <b>{{ dt.ShipAddress.Code }}</b><br>
                                Address : 
                                {{
                                    dt.IsOneTime ? 
                                    dt.OcData.shipto_address + ', ' + dt.OcData.city + ', ' + dt.OcData.country : 
                                    dt.ShipAddress.Address + ', ' + dt.ShipAddress.City + ', ' + dt.ShipAddress.Country
                                }}<br>
                            </vs-td>
                            <vs-td>
                                {{ dt.PoReferenceCode }}<br>
                            </vs-td>
                            <vs-td>
                                Code : {{ dt.Code }}<br>
                                Date : {{ dateFormat(dt.Date) }}<br>
                                Note : {{ dt.Notes }}<br>
                            </vs-td>
                            <vs-td>
                                {{ dt.Category }}<br>
                            </vs-td>
                            <vs-td>
                                Delivery Type : {{ dt.DeliveryType?dt.DeliveryType.Code + " - " + dt.DeliveryType.Name:"" }}<br>
                                Req. Delivery Date : {{ dateFormat(dt.RequestDeliveryDate) }}<br>
                                Delivery Due : {{ getDeliveryDue(dt.RequestDeliveryDate) + " days" }}<br>
                                Allow Partial : {{ dt.AllowPartial == 0 ? "No" : "Yes" }}<br>
                                Flush Out : {{ dt.IsFlushOut ? "Yes" : "No" }}<br>
                            </vs-td>
                            <vs-td>
                                Territory : <b>{{ dt.Territory?dt.Territory.Code + " " + dt.Territory.Name:"" }}</b><br>
                                Dist. Channel : {{ dt.Customer.CustomerCategory.Name }}<br>
                                Salesman : {{ dt.SalesPersonalCodeExternal != "" && dt.SalesPersonalCodeExternal ? 
                                    ' [' + dt.SalesPersonalCodeExternal + '] ' + dt.SalesPersonalName : 
                                    ' [ - ] ' + dt.SalesPersonalName 
                                    }}<br>
                            </vs-td>
                            <vs-td>
                                Total Line : {{ dt.CountSoItem }}<br>
                                Total Order : {{ formatCurrency(dt.Total) }}
                            </vs-td>
                            <vs-td>
                                <!-- auto cancel at -->
                                <b>Notes : </b>{{ getValidationNote(dt) }}<br>
                                <b>Fulfillment Notes : </b>{{ dt.FulfillmentMessage }}<br>
                                <b>Status : </b>{{ getSoStatus(dt) }}<br>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
        
                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
        <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <detailSelectedSo :selectedSO="selectedSO"></detailSelectedSo>
                </div>
            </div>
        </vs-popup>
    </div>
</template>


<script>
import detailSelectedSo from "../component/detail_selected_so.vue";
import moment from 'moment'

export default {
    components: {
        detailSelectedSo
    },
    props: 
        ["territory", "salesChannel", "customerCategory", "salesPersonal", "doFilter", "sources","dateStart","dateEnd","SelectedOrderTypes", "userPermission",],
        mounted() {
            this.selectedSO = this.dataSalesOrder
            console.log("this.selectedSO", this.selectedSO);
            console.log("this.userPermission", this.userPermission);
            // this.$store.dispatch("user/getPermissions","sales-order");
            if ( this.userPermission == undefined ) {
                this.$store.dispatch("user/getPermissions","sales-order");
                console.log("this.$store", this.$store)
                console.log("this.$store.getters['user/hasPermissions']('view')", this.$store.getters['user/hasPermissions']('view'))
                this.userPermission = {
                    view: this.$store.getters['user/hasPermissions']('view'),
                    edit: this.$store.getters['user/hasPermissions']('edit'),
                    create: this.$store.getters['user/hasPermissions']('create'),
                    delete: this.$store.getters['user/hasPermissions']('delete'),
                    cancel: this.$store.getters['user/hasPermissions']('cancel'),
                }
                console.log("this.userPermission", this.userPermission)
            }
        },
        data() {
            return {
                selectedSO: {},
                dataSalesOrder: [{
                    SalesOrderLine: [{}],
                    Customer: {
                        Code: "",
                        Name: "",
                        Address: "",
                        Phone: "",
                        CustomerCategory: {
                            Name: ""
                        }
                    },
                    Company: {
                        Code: "",
                        Name: "",
                    },
                    DefaultAttribute: {
                        ID: "",
                    },
                    SalesChannel: {
                        Code: "",
                        Name: ""
                    },
                    BillAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    ShipAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    Salesman: {
                        Name: "",
                    },
                    PaymentTerm: {
                        Name: "",
                    }
                }],
                salesOrderCode: "",

                limits: [10, 25, 50, 100],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                search: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,
                checkedAll: false,
                cancelAll: false,
                checked_so: [],
            };
        },
    watch: {
        doFilter: function() {
            this.getData();
        },
    },
    computed: {
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        customerLimitPercentage(order, balance) {
            let result = ((order - balance) / balance) * 100
            return result.toFixed(2)
        },
        dateFormat(date) {
            if (date) {
                return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
            }
        },
        getDeliveryDue(date){
            if (date) {
                var rdd = moment(String(date));
                var today = moment();
                return today.diff(rdd, "days")
            }
        },
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getSOLine () {
            const search = this.salesOrderCode;
            if (search) {
                this.selectedSO = this.dataSalesOrder.filter(so => so.Code.indexOf(search) > -1)[0] // filter + get 1st record
            }
        },
        clickSOCode(salesOrder) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            this.salesOrderCode = salesOrder.Code;
            this.getSOLine();
            this.detail = true;
            this.scrollToTop();
        },
        clickEdit(salesOrder) {
            this.$emit("selectSalesOrder", salesOrder);
            this.$emit("redirectTab", 3, '');
        },
        clickDelete(salesOrder) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You are about to cancel '"+salesOrder.Code+"' ?",
                accept: this.actionDelete,
                parameters: salesOrder
            });
        },
        actionDelete(salesOrder) {
            this.$vs.loading();
            this.$http
                .post("api/v1/sales-order/cancel", {
                    sales_order_code: salesOrder.Code,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Sales Order successfully cancelled",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    this.getData();
                    this.$vs.loading.close();
                });
        },
        closeDetail() {
            this.detail = false;
            this.$emit("selectSalesOrder", null);
        },
        page(page) {
            this.getData(page);
            return page;
        },
        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },
        getData(page = 1) {
            if(this.territory != null && this.territory != "" && this.territory != undefined) {
                this.isActive = page;
                this.$vs.loading();
                var sources = []
                var soTypes = []

                this.sources.forEach((el)=>{
                    sources.push(el.source)
                })
                console.log(this.SelectedOrderTypes)
                this.SelectedOrderTypes.forEach((el)=>{
                    soTypes.push(el.code)
                })
                // sales-order-mix2
                // this.$http.get("/api/v1/sales-order", {
                this.$http.get("/api/v1/sales-order-mix2", {
                    params: {
                        territory_code: this.territory,
                        sales_channel_code: this.salesChannel,
                        customer_category_id: this.customerCategory,
                        sales_personal_id: this.salesPersonal,
                        start_date:this.dateStart,
                        end_date:this.dateEnd,
                        sources:sources,
                        so_types:soTypes,
                        approval_status: 0,
                        approval_manager: 0,
                        approval_release: 0,
                        validation_status: 0,
                        // allocation_status: 0,
                        search: this.search,
                        limit: this.limitShow,
                        offset: this.limitShow * (page - 1)
                    }
                }).then(resp => {
                    if (resp.code == 200) {
                        this.drawData = this.drawData + 1;
                        this.dataSalesOrder = resp.data.SalesOrders;
                        this.recordsTotal = resp.data.RecordsTotal;
                        this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                        this.dataEnd = resp.data.ResultsTotal;
                        this.checkedAll = false;
                        this.checked_so = [];
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                });
            }
        },
        checkProcess(soID) {
            if (this.checked_so.includes(soID)) {
                this.checked_so.splice(this.checked_so.indexOf(soID), 1); // unchecked approve
            } else {
                this.checked_so.push(soID); // checked approve
            }
            this.checkedAll = this.checked_so.length == this.dataSalesOrder.length ? true :  false;
            console.log("this.checked_so", this.checked_so);
        },
        checkAllProcess() {
            if (this.checkedAll == false) {
                for (var i = 0; i < this.dataSalesOrder.length; i++) {
                    if (!this.checked_so.includes(this.dataSalesOrder[i].DefaultAttribute.ID)) {
                        this.checked_so.push(this.dataSalesOrder[i].DefaultAttribute.ID);
                    }
                }
            } else {
                for (var j = 0; j < this.dataSalesOrder.length; j++) {
                    this.checked_so.splice(this.checked_so.indexOf(this.dataSalesOrder[j].id), 1);
                }
            }
        },
        handleProcess(){
            if(this.checked_so.length == 0){
                this.$vs.notify({
                    color: "warning",
                    title: "Warning",
                    text: "Please select at least one data !",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-alert-circle"
                });
                return;
            }
            console.log("handleProcess")
            console.log("this.checked_so", this.checked_so)
            this.$vs.dialog({
                type: "confirm",
                color: "success",
                title: `Confirm`,
                text: "Are you sure to process these selected SO ?",
                accept: this.actionProcess
                // parameters: salesOrder
            });
        },
        
        actionProcess() {
            this.$vs.loading();
            let sales_order_ids = this.checked_so;
            console.log('sales_order_ids', sales_order_ids)
            this.$http
                .post("api/v1/sales-order/auto-validation", {
                    sales_order_ids: sales_order_ids,
                    is_auto_validation: true,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Sales Order successfully processed",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check-circle"
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    this.getData();
                    this.$vs.loading.close();
                });
        },
        handleCancel(){
            if(this.checked_so.length == 0){
                this.$vs.notify({
                    color: "warning",
                    title: "Warning",
                    text: "Please select at least one data !",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-alert-circle"
                });
                return;
            }
            console.log("handleCancel")
            console.log("this.checked_so", this.checked_so)
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel these selected SO ?",
                accept: this.actionCancels
                // parameters: salesOrder
            });
        },
        actionCancels() {
            this.$vs.loading();
            let sales_order_ids = this.checked_so;
            console.log('sales_order_ids', sales_order_ids)
            this.$http
                .post("api/v1/sales-order/cancel", {
                    sales_order_ids: sales_order_ids,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Sales Order successfully cancelled",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check-circle"
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    this.getData();
                    this.$vs.loading.close();
            });
        },
        handleFilters(){
            this.$emit("handleFilters");
        },
        getValidationNote(dt) {
          if(dt.Notes.includes("auto cancel at")) {
            return dt.Notes + "\n" + dt.ValidationNotes
          } else {
            return dt.ValidationNotes;
          }
        },
        getSoStatus(so) {
            if(so.IsClosed == 1) {
                return 'Closed'
            } else if(so.IsCancel == 1) {
                return 'Canceled'
            } else if(so.ApprovalManager == 2 || so.ApprovalStatus == 2) {
                return 'Rejected'
            } else if(so.ApprovalManager == 3) {
                return 'Waiting Approval'
            } else if(so.ValidationStatus == 2) {
                return 'Hold'
            } else if(so.ValidationStatus == 1) {
                if (so.InvoiceCode != "") {
                    return 'Billed'
                } else {
                    return 'Released PI'
                }
            } else if(so.AllocationStatus == 6) {
                return 'Allocating ....'
            } else {
                if(so.Category == 'ZDCBD' && ((so.ValidationNotes != "" && so.ValidationNotes != null) || so.FulfillmentMessage != "")) {
                    return 'Reprocess'
                }
                return 'New CBD SO'
            }
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>