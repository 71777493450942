<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Sales Order" style="z-index:10" class="pt-2">
            <!-- <div class="vx-row mb-6" style="width:50%">
                <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)">Back</vs-button>
            </div> -->
            <div v-if="showFilters">
                <div v-if="showFilterTerr" class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Territory</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            class="selectExample"
                            v-model="territory"
                            :options="optionTerritory"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder="Type to search"
                            track-by="code"
                            label="name"
                        >

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                </div>
                            </template>

                        </multiselect>
                    </div>
                </div>
                <div v-if="showFilterSc" class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Sales Channel</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            class="selectExample"
                            v-model="salesChannel"
                            :options="optionSalesChannel"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder="Type to search"
                            track-by="code"
                            label="name"
                        >

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                                </div>
                            </template>

                        </multiselect>
                    </div>
                </div>
                <div v-if="showFilterDc" class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Distribution Channel</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            class="selectExample"
                            v-model="customerCategory"
                            :options="optionCustomerCategory"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder="Type to search"
                            track-by="ID"
                            label="name"
                        >

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </div>
                            </template>

                        </multiselect>
                    </div>
                </div>
                <!--  -->
                <div v-if="showFilterSales" class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Sales ID</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            class="selectExample"
                            v-model="salesPersonal"
                            :options="optionSalesPersonal"
                            :multiple="false"
                            :allow-empty="true"
                            :max-height="120"
                            :limit="3"
                            placeholder="Type to search"
                            :loading="isLoadingSales"
                            track-by="ID"
                            label="Name"
                            @search-change="handlerSearchSales"
                        >

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.Name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.Name }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                </div>

                <div class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Source</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            class="selectExample"
                            v-model="selectedSources"
                            :options="listSource"
                            :multiple="true"
                            :allow-empty="false"
                            :max-height="120"
                            :limit="3"
                            placeholder="Type to search"
                            :select-label="''"
                            deselect-label=""
                            track-by="id"
                            @input="handleSelectSource"
                            label="name"
                        >

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </div>
                            </template>

                        </multiselect>
                    </div>
                </div>

                <div class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Order Type</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            class="selectExample"
                            v-model="selectedOrderTypes"
                            :options="listOrderTypes"
                            :multiple="true"
                            :allow-empty="false"
                            :max-height="120"
                            :limit="3"
                            placeholder="Type to search"
                            :select-label="''"
                            deselect-label=""
                            track-by="id"
                            @input="handleSelectOrderType"
                            label="name"
                        >

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ (props.option.code?props.option.code+" - ":"") + props.option.name }}</span>
                                </div>
                            </template>

                        </multiselect>
                    </div>
                </div>

                <div class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Date Start</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <datepicker
                            name="date_start"
                            format="yyyy-MM-dd"
                            :inline="false"
                            v-model="dateStart"
                            placeholder="Select Date"
                            :disabledDates="{
                                from: dateEnd,
                            }"
                        ></datepicker>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Date End</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <datepicker
                            name="date_end"
                            format="yyyy-MM-dd"
                            :inline="false"
                            v-model="dateEnd"
                            placeholder="Select Date"
                            :disabledDates="{
                                to: dateStart,
                            }"
                        ></datepicker>
                    </div>
                </div>
                <div v-if="showFilters && userPermission.view" class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <vs-button class="float-left" color="primary" style="width:100%;" @click="handleFilters()">
                            Filter
                        </vs-button>
                    </div>
                </div>
                <!-- <div v-if="showFilterStatus" class="vx-row mb-6" style="width:50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Status</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            class="selectExample"
                            v-model="customerCategory"
                            :options="optionCustomerCategory"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder="Type to search"
                            track-by="ID"
                            label="name"
                        >

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </div>
                            </template>

                        </multiselect>
                    </div>
                </div> -->
            </div>
        </vx-card>
        <vx-card title="" style="z-index:9">
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(0)" label="New SO">
                    <div class="con-tab-ejemplo">
                        <br>
                        <newSO 
                            :doFilter="doFilter" 
                            :dateStart="dateStart" :dateEnd="dateEnd" 
                            :sources="selectedSources" :territory="territory.code" :salesChannel="salesChannel.code" 
                            :customerCategory="customerCategory.ID" :salesPersonal="salesPersonal.ID"  
                            v-on:selectSalesOrder="updateSelectedSalesOrder" 
                            v-on:redirectTab="redirectTab"  
                            v-on:handleFilters="handleFilters" 
                            :SelectedOrderTypes="selectedOrderTypes" 
                            :listOrderTypes="listOrderTypes" 
                            :userPermission="userPermission">
                        </newSO>
                    </div>
                </vs-tab>
                <!-- <vs-tab @click="changeTab(1)" label="Manual">
                    <div class="con-tab-ejemplo">
                        <br>
                        <manual :doFilter="doFilter" :dateStart="dateStart" :dateEnd="dateEnd" :sources="selectedSources" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID" :salesPersonal="salesPersonal.ID"  v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab"  v-on:handleFilters="handleFilters" :SelectedOrderTypes="selectedOrderTypes" :listOrderTypes="listOrderTypes"></manual>
                    </div>
                </vs-tab> -->
                <vs-tab @click="changeTab(1)" label="CBD SO">
                    <div class="con-tab-ejemplo">
                        <br>
                        <cbdSO 
                            :doFilter="doFilter" :dateStart="dateStart" :dateEnd="dateEnd" 
                            :sources="selectedSources" :territory="territory.code" :salesChannel="salesChannel.code" 
                            :customerCategory="customerCategory.ID" :salesPersonal="salesPersonal.ID"  
                            v-on:selectSalesOrder="updateSelectedSalesOrder" 
                            v-on:redirectTab="redirectTab" 
                            v-on:handleFilters="handleFilters" 
                            :SelectedOrderTypes="cbdTypeFilter" 
                            :userPermission="userPermission">
                        </cbdSO>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(2)" label="SO Tracker">
                    <div class="con-tab-ejemplo">
                        <br>
                        <monitoring 
                            :doFilter="doFilter" :dateStart="dateStart" :dateEnd="dateEnd" 
                            :sources="selectedSources" :territory="territory.code" :salesChannel="salesChannel.code" 
                            :customerCategory="customerCategory.ID" :salesPersonal="salesPersonal.ID" 
                            v-on:selectSalesOrder="updateSelectedSalesOrder" 
                            v-on:setAsNewSo="setAsNewSo" 
                            v-on:redirectTab="redirectTab" 
                            v-on:handleFilters="handleFilters" 
                            :SelectedOrderTypes="selectedOrderTypes" 
                            :userPermission="userPermission">
                        </monitoring>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(3)" label="Form">
                    <div class="con-tab-ejemplo">
                        <br>
                        <formSO
                            :territoryCode="territory.code"
                            :selectedTerritory="territory"
                            :selectedDistChannel="customerCategory"
                            :selectedSalesChannel="salesChannel"
                            :territoryId="territory.id"
                            :selectedSO="selectedSO"
                            :optionTerritory="optionTerritory"
                            :redirectFrom="redirectFrom"
                            :IsCancel="IsCancel"
                            :userPermission="userPermission"
                            v-on:selectSalesOrder="updateSelectedSalesOrder"
                            @successSave="changeTab"
                            @redirectTab="redirectTab"
                        ></formSO>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(4)" label="Allocation Form">
                    <div class="con-tab-ejemplo">
                        <br>
                        <formAllocation 
                            :territoryCode="territory.code" 
                            :selectedTerritory="territory" 
                            :selectedDistChannel="customerCategory" 
                            :selectedSalesChannel="salesChannel" 
                            :territoryId="territory.id" 
                            :selectedSO="selectedSO" 
                            :optionTerritory="optionTerritory" 
                            :redirectFrom="redirectFrom"
                            :IsCancel="IsCancel"
                            :userPermission="userPermission"
                            v-on:selectSalesOrder="updateSelectedSalesOrder"
                            @successSave="changeTab"
                            @redirectTab="redirectTab"
                        ></formAllocation>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import newSO from "./new_so/datatable.vue";
import cbdSO from "./cbd_so/datatable.vue";
import validationSO from "./validation/datatable.vue";
import formSO from "./form/form.vue";
import Datepicker from "vuejs-datepicker";
import releaseSO from "./release_so/datatable.vue";
import monitoring from "./monitoring/index.vue";
import manual from "./manual/datatable.vue";
import formAllocation from "./form_allocation/form.vue";

export default {
    components: {
        Datepicker,
        newSO,
        cbdSO,
        validationSO,
        formSO,
        releaseSO,
        monitoring,
        manual,
        formAllocation
    },
    mounted() {
        let id = this.$route.params.id
        let debug = this.$route.query.debug
        if (id) {
            this.getOneDataByID(id,debug)
        }
        console.log("debug", debug)
        console.log("this.selectedSO", this.selectedSO)
        console.log("this.$userLogin", this.$userLogin)
        this.$store.dispatch("user/getPermissions","sales-order");
        this.$vs.loading();
        Promise.all([
          this.getOptionTerritory(),
          this.getOptionSalesChannel(),
          this.getOptionCustomerCategory(),
          this.getOptionSalesID(),
        ]).then((r) => {
            console.log("this.$store", this.$store);
            console.log(r);
            this.isLoadingSales = false;
            console.log("this.$store", this.$store)
            console.log("this.userPermission", this.userPermission)
            console.log("this.$store.getters['user/hasPermissions']('view')", this.$store.getters['user/hasPermissions']('view'))
            if (this.userPermission == null){
                this.userPermission = {
                    view: this.$store.getters['user/hasPermissions']('view'),
                    edit: this.$store.getters['user/hasPermissions']('edit'),
                    create: this.$store.getters['user/hasPermissions']('create'),
                    delete: this.$store.getters['user/hasPermissions']('delete'),
                    cancel: this.$store.getters['user/hasPermissions']('cancel'),
                }
            }
            console.log("this.userPermission", this.userPermission)
            this.$vs.loading.close();          
        });
        // this.getOptionTerritory();
        // this.getOptionSalesChannel();
        // this.getOptionCustomerCategory();
        // this.getOptionSalesID(true);
        // console.log("mounted", this.selectedSO);
        this.getListSelectOrderType()
    },
    data: () => ({
        doFilter: 0,
        tabColor:['success', 'primary', 'warning', 'rgb(62, 201, 214)', 'success', 'rgb(62, 201, 214)'],
        dateStart: new Date(),
        dateEnd: new Date(),
        isAdmin:false,
        listSource:[
            {
                id:-1,
                name: "ALL",
                source:""
            },
            {
                id:0,
                name: "SFA",
                source:"sfa-mobile"
            },
            {
                id:1,
                name: "Back Office",
                source:"sfa-web"
            },
            {
                id:2,
                name: "Auto-Pool",
                source:"ext-json"
            }
        ],
        listOrderTypes:[{
            id:-1,
            name: "ALL",
            code:""
        }],
        selectedSources:[{
            id:-1,
            name: "ALL",
            source:""
        }],
        selectedOrderTypes:[{
            id:-1,
            name: "ALL",
            code:""
        }],

        cbdTypeFilter:[],

        tabIndex: 0,
        territory: {},
        optionTerritory: [{}],
        AlloptionTerritory: [{}],
        salesChannel: {},
        AlloptionSalesChannel:[{}],
        optionSalesChannel:[{}],
        customerCategory: {},
        optionCustomerCategory:[{}],
        AlloptionCustomerCategory:[{}],
        salesPersonal: {},
        optionSalesPersonal:[{}],
        AllOptionSalesPersonal:[{}],
        colorx: "success",
        selectedSO: {},
        showFilters: true,
        showFilterTerr: true,
        showFilterSc: false,
        showFilterDc: true,
        showFilterSales: true,
        isLoadingSales: false,
        redirectFrom: '',
        IsCancel: 0,
        searchSales: "",
        userPermission: null,
    }),
    watch: {
        customerCategory: function() {
            console.log("this.customerCategory", this.customerCategory);
            if(this.customerCategory.name == 'All'){

            }
        },
        territory: function() {
            if(this.salesPersonal.Name == 'All' && this.salesPersonal.ID == undefined){
                this.getOptionSalesID().then(r => {
                    console.log(r);
                });
            }
        },
        salesChannel: function() {
            if(this.salesPersonal.Name == 'All' && this.salesPersonal.ID == undefined){
                this.getOptionSalesID().then(r => {
                    console.log(r);
                });
            }
        },
        salesPersonal: function() {
            if(this.salesPersonal.Name != 'All' && this.salesPersonal.SalesChannelId != 0){
                this.salesChannel = this.optionSalesChannel.filter((v) => {
                    return v.ID == this.salesPersonal.SalesChannelId;
                });
                this.salesChannel = this.salesChannel[0];
            }
        },
    },
    methods: {
        getOneDataByID(so_id,debug) {
            this.$vs.loading();
            this.$http.get("/api/v1/sales-order-mix2", {
                params: {
                    sales_order_id:so_id,
                }
            }).then(resp => {
                this.$vs.loading.close();
                console.log("test bossku=>",resp.data.SalesOrders)
                if (resp.data.SalesOrders&&resp.data.SalesOrders.length>0) {
                    this.$vs.loading();
                    this.updateSelectedSalesOrder(resp.data.SalesOrders[0])
                    if (debug=="true") {
                        this.redirectTab(3,'debug','All')
                    }else{
                        this.redirectTab(3,'monitoring','All')
                    }
                    this.$vs.loading.close();
                }
                if (resp.code == 200) {

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
        handleFilters(){
            this.doFilter++;
            console.log("this.doFilter", this.doFilter)
            console.log("this.territory", this.territory)
            console.log("this.salesChannel", this.salesChannel)
            console.log("this.customerCategory", this.customerCategory)
            console.log("this.salesPersonal", this.salesPersonal)
            console.log("this.source", this.selectedSource)
            console.log("this.dateStart", this.dateStart)
            console.log("this.dateEnd", this.dateEnd)
            console.log("this.SelectedOrderTypes", this.selectedOrderTypes  )
        },
        changeTab(index) {
            // console.log("before", this.tabIndex);
            console.log("idx", index);
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
            if(index == 4 || index == 3) {
                window.scrollTo(0,0);
                this.showFilters        = false;
                this.showFilterTerr     = false;
                this.showFilterSc       = false;
                this.showFilterDc       = false;
                this.showFilterSales    = false;
                // this.showFilterStatus   = false;
            } else {
                this.showFilters        = true;
                this.showFilterTerr     = true;
                this.showFilterSc       = false;
                this.showFilterDc       = true;
                this.showFilterSales    = true;
                // this.showFilterStatus   = true;
            }
        },
        getOptionTerritory() {
            return new Promise((resolve, reject) => {
                this.$http.get("/api/v1/master/multiple-territory").then(resp => {
                    if(resp.code == 200){
                        this.optionTerritory = []
                        if (resp.data.is_admin || (this.$userLogin.territory == "" && this.$userLogin.territory_id == 0)){
                            this.optionTerritory.push({code:'All'})
                        }
                        for(var ter = 0; ter<resp.data.records.length; ter++){
                            this.optionTerritory.push(resp.data.records[ter])
                        }
                        if (this.optionTerritory.length>0) {
                            this.territory = this.optionTerritory[0];
                        }
                        this.AlloptionTerritory = this.optionTerritory;
                        resolve(true)
                    }else{
                        this.$vs.loading.close();
                        reject(false)
                        // this.$router.push("/");
                    }
                });
            });
        },
        getOptionSalesChannel() {
            return new Promise((resolve, reject) => {
                let url = "/api/v1/sales-channel/list";
                this.$http.get(url).then(
                  resp => {
                    // console.log("3039",resp)
                    if(resp.code == 200){
                      this.optionSalesChannel = []
                      this.optionSalesChannel.push({code:'All'})
                      for(var salesChannel = 0; salesChannel<resp.data.length; salesChannel++){
                        this.optionSalesChannel.push(resp.data[salesChannel])
                      }
                      this.AlloptionSalesChannel = this.optionSalesChannel;
                      this.salesChannel = this.optionSalesChannel[0];
                      console.log("resolve 3 lho - 1040")
                      resolve(true)
                    }else{
                      // this.$router.push("/");
                      console.log(resp);
                      reject(false)
                    }
                  }
                );
              });
        },
        getOptionCustomerCategory() {
            // this.$http.get("/api/v1/master/customer-category-all").then(
            // this.$http.get("/api/v1/master/customer-category-all").then(
            //     resp => {
            //         if(resp.code == 200){
            //             this.optionCustomerCategory = []
            //             this.optionCustomerCategory.push({name:'All'})
            //             for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
            //                 this.optionCustomerCategory.push(resp.data[customerCategory])
            //             }
            //             this.customerCategory = this.optionCustomerCategory[0];
            //             this.AlloptionCustomerCategory = this.optionCustomerCategory;
            //         }else{
            //             // this.$vs.loading.close();
            //             this.$router.push("/");
            //         }
            //     }
            // );
            return new Promise((resolve, reject) => {
                this.$http.get("/api/v1/master/customer-category-all").
                then(resp => {
                    if(resp.code == 200){
                        this.optionCustomerCategory = []
                        this.optionCustomerCategory.push({name:'All'})
                        for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
                            this.optionCustomerCategory.push(resp.data[customerCategory])
                        }
                        this.customerCategory = this.optionCustomerCategory[0];
                        this.AlloptionCustomerCategory = this.optionCustomerCategory;
                        resolve(true)
                    }else{
                      reject(false)
                    }
                  }
                );
            });
        },
        getOptionSalesID() {
            console.log("this.territory",this.territory)
            console.log("this.salesChannel",this.salesChannel)
            return new Promise((resolve, reject) => {
                this.isLoadingSales = true
                let territory_id = (this.territory.code != 'All' && this.territory.id) ? this.territory.id : 0;
                let sales_channel_id = this.salesChannel.code != 'All' ? this.salesChannel.ID : 0;
                this.$http.get("/api/v1/sales-order/personals",{
                    params: {
                      search: this.searchSales,
                      territory_id: territory_id,
                      sales_channel_id: sales_channel_id,
                    }
                }).then(resp => {
                    if(resp.code == 200){
                        this.optionSalesPersonal = []
                        this.optionSalesPersonal.push({Name:'All'})
                        let data = resp.data.salesman
                        for(var idx = 0;idx<data.length;idx++){
                            this.optionSalesPersonal.push(data[idx])
                        }
                        if (this.searchSales == ""){
                            this.salesPersonal = this.optionSalesPersonal[0];
                        }
                        console.log("resolve 5 lho - 1151")
                        this.isLoadingSales = false
                        resolve(true)
                    } else {
                      reject(false)
                    }
                  }
                );
            });
        },
        handlerSearchSales(search){
            this.searchSales = search
            if(search != ""){
                this.getOptionSalesID();
            }
        },

        updateSelectedSalesOrder(salesOrder, progress) {
            this.selectedSO = salesOrder;
            this.changeTab(3);
            console.log("selected", this.selectedSO);
            console.log("progress", progress);
            this.IsCancel = this.selectedSO.IsCancel;
        },
        redirectTab(index, from, progress) {
            this.$refs.tabs.activeChild(index);
            this.redirectFrom = from;
            console.log("from", from);
            console.log("progress", progress);
            if(progress == 'cancel' || this.selectedSO.IsCancel == 1) {
                this.IsCancel = 1;
            } else {
                this.IsCancel = 0;
            }
            console.log("this.IsCancel", this.IsCancel);
        },
        handleSelectSource(v) {
            if (v.length > 0 && v[v.length-1].id == -1) {
                this.selectedSources = this.selectedSources.filter((v)=>{
                    return v.id ==-1
                })
            }else{
                this.selectedSources = this.selectedSources.filter((v)=>{
                    return v.id !=-1
                })
            }
        },
        getListSelectOrderType(){
            this.$http.get("/api/v1/master/sales-order-type", {
                params: {
                    order: 'name',
                    sort: 'asc',
                }
            }).then(resp => {
                console.log("order type bro",resp)
                if (resp.data.records.length>0) {
                    resp.data.records.forEach((el)=>{
                        if (el.Code =='ZDCBD') {
                            this.cbdTypeFilter.push({
                                id:el.ID,
                                name:el.Name,
                                code:el.Code,
                            })
                            // SO Cbd juga ditampilkan di tracker, maka opsi ZDCBD juga ditampilkan di filter
                            this.listOrderTypes.push({
                                id:el.ID,
                                name:el.Name,
                                code:el.Code,
                            })
                        }else{
                            this.listOrderTypes.push({
                                id:el.ID,
                                name:el.Name,
                                code:el.Code,
                            })
                        }
                    })
                }
            });
        },
        handleSelectOrderType(v){
            console.log("selectedOrderTypes",this.selectedOrderTypes)
            if (v.length > 0 && v[v.length-1].id == -1) {
                this.selectedOrderTypes = this.selectedOrderTypes.filter((v)=>{
                    return v.id ==-1
                })
            }else{
                this.selectedOrderTypes = this.selectedOrderTypes.filter((v)=>{
                    return v.id !=-1
                })
            }
        },
        setAsNewSo(salesOrder, progress) {
            this.$vs.loading();
            console.log("salesOrder", salesOrder)
            console.log("progress", progress)

            let baseUrl = "/api/v3/sales-order/set_as_newso/" + salesOrder.DefaultAttribute.ID;

            this.$http.put(baseUrl).then((resp) => {
                console.log(resp)
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check-circle",
                    });
                    this.redirectTab(0,'monitoring','All')
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
        updateSelectedSO(salesOrder, progress) {
            this.selectedSO = salesOrder;
            this.changeTab(3);
            console.log("selected", this.selectedSO);
            console.log("progress", progress);
            this.IsCancel = this.selectedSO.IsCancel;
        },
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>
